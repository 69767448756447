const PREFIXES = {
  LOCAL: "http://127.0.0.1:8000",
  TEST: "http://kominos.pl",
  PROD: "https://rcp.kubala.com",
};

const PREFIX = PREFIXES.PROD;

export const API = {
  AUTH: `${PREFIX}/api/auth/`,
  EVENT: `${PREFIX}/api/event/`,
  DEPARTMENT: `${PREFIX}/api/department/`,
  PROJECT: `${PREFIX}/api/project/`,
  USER: `${PREFIX}/api/user/`,
  PEOPLE: `${PREFIX}/api/people/`,
  CALENDAR: `${PREFIX}/api/people/monthtime/`,
  WORKLOG: `${PREFIX}/api/people/subordinates/day/`,
  ABSENCE_CALENDAR: `${PREFIX}/api/calendar/`,
  PEOPLE_ONLINE: `${PREFIX}/api/people/online/`,
  STATISTICS: `${PREFIX}/api/stats/`,
  ROLES: `${PREFIX}/api/group/role/`,
  GROUP: `${PREFIX}/api/group/`,
  ACCESS: `${PREFIX}/api/accesscontrol/`,
  READERS: `${PREFIX}/api/readers/`,
  BUDGET: `${PREFIX}/budget/api/budget/`,
  INVOICE: `${PREFIX}/budget/api/invoice/`,
  CATEGORY: `${PREFIX}/budget/api/category/`,
  CONTRACTOR: `${PREFIX}/budget/api/contractor/`,
  ATTACHMENT: `${PREFIX}/budget/api/attachments/`,
  SUMMARY: `${PREFIX}/budget/api/invoice/summary/`,
  HISTORY: `${PREFIX}/budget/api/invoice/history/`,
  ARCHIVE: `${PREFIX}/budget/api/invoice/archive/`,
  TYPES: `${PREFIX}/budget/api/invoice/types/`,
  LICENSE: `${PREFIX}/api/license/`,
  CONTRACTS: `${PREFIX}/budget/api/contracts/`,
};
