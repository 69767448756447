import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat", "Roboto", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: { main: "#2C666E" },
    secondary: { main: "#028090" }
  },
  overrides: {
    MTableToolbar: {
      root: {
        background: "red"
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiTableCell: {
      root: {
        background: "red",
        fontFamily:
          ["Montserrat", "Roboto", "Arial", "sans-serif"].join(",") +
          " !important"
      }
    }
  }
});
