export const INVOICE_STAGE = {
	UNASSIGNED: 0,
	SENT_TO_REVIEW: 1,
	ACCEPTED: 2,
	REJECTED: 3,
	ACCOUNTING: 4
};

export const INVOICE_STAGE_TEXT = {
	0: 'Nieprzypisana',
	1: 'Wysłana do akceptacji',
	2: 'Zaakceptowana',
	3: 'Odrzucona',
	4: 'W księgowości'
};
