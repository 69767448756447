import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
    TextField,
} from '@material-ui/core';

const NewContractorDialog = ({ open, handleClose, handleSubmit }) => {

    const [newContractor, setNewContractor] = useState({})

    const handleChange = (event) => {
        const {name, value} = event.target;

        setNewContractor({
            [name]: value
        });
    }

    const handleSubmitInternal = () => {
        handleSubmit(newContractor);
        setNewContractor({
            name: null,
        });
    }

    const handleCloseInternal = () => {
        handleClose();
        setNewContractor({
            name: null,
        });
    }
 
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'md'}
			aria-labelledby="contractor-dialog-title"
			aria-describedby="contractor-dialog-description"
		>
			<DialogTitle id="contractor-dialog-title">Tworzenie nowego kontrahenta</DialogTitle>
			<DialogContent>
                <Grid container justify="space-around" spacing={2} alignItems="center">
                    <Grid item>
                        <TextField
                            label="Nazwa"
                            value={newContractor["name"]}
                            name="name"
                            onChange={handleChange}
                        />
                    </Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmitInternal} color="primary">
					Utwórz
				</Button>
				<Button onClick={handleCloseInternal} color="primary">
					Anuluj
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NewContractorDialog;
