import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	TextField,
	DialogActions,
	Button,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Select from '../../common/Select';
import { getProjects } from '../../../services/administrationService';
import { validateFileExtension } from "../../../utils/FileValidatior";
import CustomLoader from "../../common/CustomLoader";

function NewBudget({ open, handleClose, addNewBudget, managers }) {
	const [ newBudgetData, setNewBudgetData ] = useState({});
	const [ projects, setProjects ] = useState([]);
	const [isLoaded, setIsLoaded] = useState(true);

	const handleDataChange = (e) => {
		let name;
		let value;
		if (!!e.target) {
			name = e.target.name;
			value = e.target.value;
		} else {
			name = e.name;
			value = e.value;
		}
		let dataObject = { [name]: value };
		if (name === 'file') {
			dataObject = { ...dataObject, ...{ name: value.name } };
		}
		setNewBudgetData({ ...newBudgetData, ...dataObject });
	};

	const fetchProjects = async () => {
		try {
			setIsLoaded(false);
			const projects = await getProjects(true);
			setProjects(projects);
		} catch (error) {
			console.log(error);
			toast.error('Błąd pobierania listy budów');
		} finally {
			setIsLoaded(true);
		}
	};

	useEffect(() => {
		fetchProjects();
	}, []);


	const objectToFormData = (object) => {
		let form_data = new FormData();

		for (const [ key, value ] of Object.entries(object)) {
			if (key === 'file') {
				form_data.append(key, value, { type: 'multipart/form-data' });
			} else {
				form_data.append(key, value);
			}
		}
		return form_data;
	};

	const handleAdd = async () => {
		try {
			if(!validateFileExtension(newBudgetData.name, ["xls", "xlsx"])){
				toast.error("Dozwolone tylko pliki .xls i .xlsx!");
				return;
			};	
			setIsLoaded(false);
			const formData = objectToFormData(newBudgetData);
			await addNewBudget(formData);
			setNewBudgetData({});
			toast.success('Dodano budżet.');
			handleClose();
		} catch (error) {
			console.log(error);
			// toast.error('Coś poszło nie tak.');
		} finally {
			setIsLoaded(true);
		}
	};

	const fileChange = async (event) => {
		const fileObject = { name: 'file', value: event.target.files[0] };
		const formData = new FormData();

		formData.append('file', event.target.files[0]);
		formData.append('name', event.target.files[0].name);
		await handleDataChange(fileObject);
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted maxWidth={"md"} fullWidth={true}>
			<CustomLoader loaded={isLoaded} />
			<DialogTitle id="form-dialog-title">Dodaj Budżet</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} justify="space-between" direction="column">
					<Grid container item spacing={2} direction="row" style={{ 'margin-bottom': '10px' }}>
						<Grid item xs={4}>
							<TextField
								onChange={handleDataChange}
								value={newBudgetData.budget_name || ''}
								label="Nazwa"
								name="budget_name"
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}>
						{projects && projects.length > 0 ? 
							<Select
								onChange={handleDataChange}
								label="Wydział"
								value={newBudgetData.project || ''}
								name={'project'}
								items={projects}
								itemValueProp="id"
								itemLabelProp="project"
								/>
								:
								<div>
									Brak projektów
								</div>
							}
						</Grid>
						<Grid item xs={4}>
							{ managers && managers.length > 0 ? 

								<Select
								onChange={handleDataChange}
								label="Kierownik kontraktu"
								value={newBudgetData.manager || ''}
								name={'manager'}
								items={managers}
								itemValueProp="worker_id"
								customLabel={(item) => `${item.first_name} ${item.last_name}`}
								/>
								:
								<div>
									Brak menadżerów
								</div>
							}
						</Grid>
					</Grid>
					<Button variant="contained" component="label">
						{!newBudgetData.name ? 'Dodaj plik' : `Załadowano plik o nazwie ${newBudgetData.name}`}
						<input type="file" style={{ display: 'none' }} onChange={fileChange} />
					</Button>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Anuluj
				</Button>
				<Button onClick={handleAdd} color="primary">
					Dodaj
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NewBudget;
