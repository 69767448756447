import { API } from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';

export const getTypes = async (invoiceType) => {
	try {
		const response = axios.get(`${API.TYPES}${invoiceType}/`);
		return response;
	} catch (error) {
		console.log(error);
		toast.error('SRTARAS');
		throw error;
	}
};

