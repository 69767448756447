import React, { useState, useEffect } from 'react';
import DefaultTable from '../../common/DefaultTable';
import { defaultColumns } from "./ContractorColumns";
import {getContractors, createContractor, contractorDelete, contractorEdit} from "../../../services/contractorService";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Grid, TextField } from '@material-ui/core';
import Tile from '../../common/Tile';
import NewContractorDialog from './NewContractorDialog';
import TooltipIconButton from '../../common/TooltipIconButton';
import GenericDialog from '../../common/GenericDialog';
import { toast } from 'react-toastify';

function Contractors () {

    const DIALOGS = {
        NEW_CONTRACTOR: 'DODAJ KONTRAHENTA',
        EDIT_CONTRACTOR: 'EDYTUJ KONTRAHENTA'
    };
    
    const emptyContractor = {name: ''};

    const [contractors, setContractors] = useState(null);
    const [openDialog, setOpenDialog] = useState(null);
    const [newContractorValues, setNewContractorValues] = useState(emptyContractor);


	const fetchContractors = async () => {
		const contractors = await getContractors();
		setContractors(contractors.data);
	};

    const handleContractorRemove = async (rowData) => {
        await contractorDelete(rowData.id);
        await fetchContractors();
    }

    useEffect(() => {
        assignActionsToColumns();
        fetchContractors();
    }, [])

    const handleContractorEdit = (rowData) => {
        setNewContractorValues(rowData);
        setOpenDialog(DIALOGS.EDIT_CONTRACTOR)
    }

    const assignActionsToColumns = () => {
        const actionIndex = defaultColumns.findIndex(x => x.title === "Akcje");
        defaultColumns[actionIndex] = {...defaultColumns[actionIndex], ...{"render": 			
            (rowData) => (
                <Grid container spacing={2}>
                        <Grid item>
                            <TooltipIconButton
                                title="Edytuj"
                                iconName="edit"
                                onClick={() => handleContractorEdit(rowData)}
                            />
                        </Grid>
                        <Grid item>
                            <TooltipIconButton
                                title="Usuń"
                                iconName="delete"
                                onClick={() => handleContractorRemove(rowData)}
                            />
                        </Grid>
                </Grid>
            )
        }}
    }

    const handleNewContractorDialogClose = () => {
        setNewContractorValues(emptyContractor)
        setOpenDialog(null);
    }

    const handleNewContractorDialogSubmit = async (newContractor) => {
        await createContractor(newContractor);
        setOpenDialog(null);
        setNewContractorValues(emptyContractor)
        fetchContractors();
    }

    const handleEditContractor = async () => {
        try {
            await contractorEdit(newContractorValues['id'], {name: newContractorValues['name']})
            fetchContractors();
            setNewContractorValues(emptyContractor)
            setOpenDialog(null);
        } catch (error) {
          console.log(error);
          toast.error('Coś poszło nie tak.');
        }
      };
    
    return (
		<Grid container direction="column" spacing={6}>
			<Grid container item spacing={2}>
				<Grid item>
					<Tile
						icon={<AddCircleOutlineIcon style={{ fontSize: '3em' }} />}
						text={DIALOGS.NEW_CONTRACTOR}
						onClick={() => setOpenDialog(DIALOGS.NEW_CONTRACTOR)}
					/>
                    <NewContractorDialog
                        open={openDialog === DIALOGS.NEW_CONTRACTOR}
                        handleClose={handleNewContractorDialogClose}
                        handleSubmit={handleNewContractorDialogSubmit}
                    />
				</Grid>
			</Grid>
			<Grid item container>
				<Grid item xs={12}>
                    <DefaultTable
                        columns={defaultColumns}
                        data={contractors || []}
                    />
				</Grid>
			</Grid>
            <GenericDialog
                open={openDialog === DIALOGS.EDIT_CONTRACTOR}
                handleClose={() => setOpenDialog(null)}
                handleConfirm={handleEditContractor}
                title="Edytuj Kontrahenta"
                content={
                    <TextField
                        label="Nazwa"
                        value={newContractorValues['name']}
                        onChange={e => setNewContractorValues({...newContractorValues, name: e.target.value})}
                        fullWidth
                    />
                }
      />

		</Grid>
        
	);
}

export default Contractors;