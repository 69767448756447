import React, { useState } from "react";
import DefaultTable from "../../common/DefaultTable";
import FillInvoiceDialog from "./FillInvoiceDialog";
import { TILES } from "../../common/TileTypes";
import { InvoiceHistoryDialog } from "./InvoiceHistoryDialog";

function InvoicesTable({
  invoices,
  columns,
  activeTile,
  fetchInvoices,
  setSelectedRow,
  isOpenInvoiceDiaglog,
  setOpenInvoiceDialog,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOrderChange,
  handleSearchChange,
  options,
  components,
}) {
  const [invoiceData, setInvoiceData] = useState(null);
  const [invid, setInvid] = useState(null);
  const [attachmentId, setAttachmentId] = useState(null);

  const handleClickOpen = async (event, data) => {
    if (isIconClick(event) && !isEditClick(event)) return;
    else if (isUploadAttachmentClick(event)) {
      return;
    }
    await setInvoiceData(data);
    switch (activeTile) {
      case TILES.FILE_LIST:
        await setInvid(data.id);
        break;
      default:
        await setAttachmentId(data.attachment_id);
        await setInvid(data.invoice_file_id);
        break;
    }
    setOpenInvoiceDialog(true);
  };

  const isIconClick = (event) =>
    event.target.className.includes("MuiIcon-root");
  const isEditClick = (event) => event.target.textContent.includes("edit");
  const isUploadAttachmentClick = (event) =>
    event.target.id.includes("upload-attachment");

  const handleClose = (shouldFetch = false) => {
    setOpenInvoiceDialog(false);
    setInvoiceData(null);
    if (shouldFetch) {
      fetchInvoices();
    }
  };

  const handleSave = () => {};

  const invoiceDialogOptions = {
    open: isOpenInvoiceDiaglog,
    setOpen: setOpenInvoiceDialog,
    handleClose,
    handleSave,
  };

  const isEditablePrice = () => {
    return !(activeTile === TILES.FILE_LIST || activeTile === TILES.UNASSIGNED);
  };

  const isEditableDate = () => {
    return !(activeTile === TILES.FILE_LIST);
  };

  const isEditableNumberContractor = () => {
    return !(
      activeTile === TILES.UNASSIGNED ||
      activeTile === TILES.FILE_LIST ||
      activeTile === TILES.PENDING ||
      activeTile === TILES.ACCEPTED ||
      activeTile === TILES.ACCOUNTING
    );
  };

  return (
    <div>
      <DefaultTable
        columns={columns}
        data={invoices}
        onSelectionChange={(rows) => setSelectedRow(rows[0])}
        onRowClick={handleClickOpen}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOrderChange={handleOrderChange}
        onSearchChange={handleSearchChange}
        options={options}
        components={components}
      />
      {activeTile === TILES.HISTORY ? (
        <InvoiceHistoryDialog
          handleClose={() => handleClose(false)}
          invoiceData={invoiceData}
          open={isOpenInvoiceDiaglog}
        />
      ) : (
        <FillInvoiceDialog
          {...invoiceDialogOptions}
          id={invid}
          attachmentId={attachmentId}
          invoiceData={invoiceData}
          isEditableNumberContractor={isEditableNumberContractor()}
          isEditablePrice={isEditablePrice()}
          isEditableDate={isEditableDate()}
          activeTile={activeTile}
        />
      )}
    </div>
  );
}

export default InvoicesTable;
