import React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    FormHelperText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    }
}));

export default function Select({
                                   name,
                                   label,
                                   value,
                                   onChange,
                                   items = [],
                                   itemValueProp,
                                   itemLabelProp,
                                   customLabel,
                                   error = false,
                                   errorText = "",
                                   disabled = false
                               }) {
    const classes = useStyles();

    if (!items || items.length < 1) {
        return null;
    }

    return (
        <FormControl className={classes.formControl} fullWidth error={error}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect name={name} value={value} onChange={onChange} fullWidth disabled={disabled}>
                {items.map(element => (
                    <MenuItem key={element[itemValueProp]} value={element[itemValueProp]}>
                        {customLabel ? customLabel(element) : element[itemLabelProp]}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
}
