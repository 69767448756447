import React, { useEffect, useState } from "react";
import { getProjects } from "../../../services/administrationService";
import ProjectsTable from "./ProjectsTable";
import {
  addProject,
  updateProject,
  deleteProject,
} from "../../../services/projectService";
import { Grid } from "@material-ui/core";
import Tile from "../../common/Tile";
import NewProject from "./NewProject";
import EditProject from "./EditProject";
import { getManagers } from "../../../services/userService";
import AssignManager from "./AssignManager";
import { toast } from "react-toastify";
import DomainIcon from "@material-ui/icons/Domain";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

const TILES = {
  ACTIVE: "AKTYWNE WYDZIAŁY",
  NOT_ACTIVE: "NIEAKTYWNE WYDZIAŁY",
};

const DIALOGS = {
  NEW_PROJECT: "DODAJ WYDZIAŁ",
};

function Projects() {
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(null);
  const [activeTile, setActiveTile] = useState(TILES.ACTIVE);
  const [editProject, setEditProject] = useState(null);
  const [managers, setManagers] = useState(null);
  const [assignManager, setAssignManager] = useState(null);

  const fetchProjects = async () => {
    try {
      const isActive = activeTile === TILES.ACTIVE ? true : false;
      const projects = await getProjects(isActive);
      setProjects(projects);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania listy budów");
    }
  };

  const addNewProject = async (newProject) => {
    try {
      await addProject(newProject);
      await fetchProjects();
      toast.success("Dodano wydział");
      return;
    } catch (error) {
      console.log(error);
      toast.error("Błąd dodawania wydziału.");
      throw error;
    }
  };

  const saveProject = async (project) => {
    try {
      await updateProject(project);
      await fetchProjects();
      toast.success("Zapisano wydział");
      setEditProject(null);
      setAssignManager(null);
    } catch (error) {
      console.log(error);
      toast.error("Błąd zapisywania wydziału");
    }
  };

  const fetchManagers = async () => {
    try {
      const response = await getManagers();
      setManagers(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania listy kierowników");
    }
  };

  const setProjectActive = async (rowData, active) => {
    try {
      const updatedProject = { ...rowData };
      updatedProject.is_active = active;
      await updateProject(updatedProject);
      toast.success("Zapisano wydział");
      fetchProjects();
    } catch (error) {
      console.log(error);
      toast.error("Błąd aktywacki/dezaktywacji wydziału");
    }
  };
  const deleteProjectHandle = async (rowData) => {
    try {
      await deleteProject(rowData);
      toast.success("Usunięto");
      fetchProjects();
    } catch (error) {
      console.log(error);
      toast.error("Błąd usuwania");
      fetchProjects();
    }
  };

  useEffect(() => {
    fetchManagers();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [activeTile]);

  return (
    <Grid container direction="column" spacing={6}>
      <Grid container item spacing={2}>
        <Grid item>
          <Tile
            icon={<DomainIcon style={{ fontSize: "3em" }} />}
            text={TILES.ACTIVE}
            onClick={() => setActiveTile(TILES.ACTIVE)}
            isActive={activeTile === TILES.ACTIVE}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<DomainDisabledIcon style={{ fontSize: "3em" }} />}
            text={TILES.NOT_ACTIVE}
            onClick={() => setActiveTile(TILES.NOT_ACTIVE)}
            isActive={activeTile === TILES.NOT_ACTIVE}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<LibraryAddIcon style={{ fontSize: "3em" }} />}
            text={DIALOGS.NEW_PROJECT}
            onClick={() => setOpenDialog(DIALOGS.NEW_PROJECT)}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <ProjectsTable
            projects={projects}
            openEditProjectDialog={setEditProject}
            openAssignManagerDialog={setAssignManager}
            setProjectActive={setProjectActive}
            isActive={activeTile === TILES.ACTIVE}
            managers={managers}
            deleteProjectHandle={deleteProjectHandle}
          />
          <NewProject
            open={Boolean(openDialog === DIALOGS.NEW_PROJECT)}
            handleClose={() => setOpenDialog(null)}
            addNewProject={addNewProject}
          />
          <EditProject
            open={Boolean(editProject)}
            handleClose={() => setEditProject(null)}
            saveProject={saveProject}
            project={editProject}
          />
          <AssignManager
            open={Boolean(assignManager)}
            handleClose={() => setAssignManager(null)}
            saveProject={saveProject}
            project={assignManager}
            managers={managers}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Projects;
