import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

function ConfirmInvoiceDialog({ open, handleClose, saveDialog, dialog, fileName }) {
	const [ newDialog, setNewDialog ] = useState({ ...dialog });

	useEffect(
		() => {
			setNewDialog({ ...dialog });
		},
		[ dialog ]
	);

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Przesyłanie pliku</DialogTitle>
			<DialogContent>
				<div>Czy zapisać na serwerze plik o nazwie {fileName && fileName.name}?</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() =>handleClose(null)} color="primary">
					Anuluj
				</Button>
				<Button onClick={() => saveDialog(newDialog)} color="primary">
					Zapisz
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmInvoiceDialog;
