import React from "react";
import DefaultTable from "../../common/DefaultTable";

function DepartmentsTable({ departments }) {
  const columns = [
    {
      title: "Dział",
      field: "department"
    },
    {
      title: "Maksymalna liczba godzin",
      field: "restriction"
    }
  ];

  return (
    <DefaultTable
      columns={columns}
      data={departments}
      title=""
      style={{ minWidth: "80%" }}
    />
  );
}

export default DepartmentsTable;
