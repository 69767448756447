import React from "react";
import DefaultTable from "../../common/DefaultTable";

function MonthlyTable({ title, days, events }) {
  let columns = [
    {
      title: "Pracownik",
      field: "name"
    },
    {
      title: "Czas całkowity",
      field: "total_hours"
    },
    {
      title: "Ilość nadgodzin",
      field: "total_over_time"
    }
  ];

  days.forEach(day => columns.push({ title: day, field: day }));

  return (
    <DefaultTable
      columns={columns}
      data={events}
      title={title}
      style={{ maxWidth: "100%" }}
      options={{
        paging: false
      }}
    />
  );
}

export default MonthlyTable;
