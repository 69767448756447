import axios from "axios";
import { API } from "../constants";
import { toast } from "react-toastify";

export const getBudgets = (filter) =>
  axios.get(`${API.BUDGET}${filter ? filter : ""}`);

export const addBudget = async (budgetData) => {
  return axios.post(API.BUDGET, budgetData);
};

export const getBudgetDetails = (budgetId) =>
  axios.get(`${API.BUDGET}details/${budgetId}/`);

export const getBudgetWorkrange = (budgetId) =>
  axios.get(`${API.BUDGET}workrange/${budgetId}`);

export const uploadNewBudgetFile = async (id, fileForm) => {
  try {
    await axios.post(`${API.BUDGET}update/${id}/`, fileForm);
    toast.success("Budżet zaktualizowany!");
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllBudgetsFiles = async () => {
  try {
    const response = await axios.get(`${API.BUDGET}budgetfile/`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getInvoicesForBudget = async (id, invoiceType) => {
  try {
    const result = await axios.get(`${API.BUDGET}invoices/${id}/${invoiceType}/`);
    return result.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteBudget = async (id) => {
  try {
    const result = await axios.post(`${API.BUDGET}delete/${id}/`);
    return result.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const archiveBudget = async (id) => {
  try {
    const result = await axios.put(`${API.BUDGET}archive/${id}/`);
    return result.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const unarchiveBudget = async (id) => {
  try {
    const result = await axios.put(`${API.BUDGET}unarchive/${id}/`);
    return result.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const downloadBudgetFile = async (id, filename) => {
  try {
    axios({
      url: `${API.BUDGET}export/${id}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename || "arkusz"}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateBudgetName = async (id, updateObject) => {
  try {
    await axios.put(`${API.BUDGET}${id}/`, updateObject);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const updateBudgetManager = async (id, updateObject) => {
  try {
    await axios.put(`${API.BUDGET}contractmanager/${id}/`, updateObject);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getSummary = async (type) => {
  try {
    const response = await axios.get(`${API.SUMMARY}${type}/`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

