import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { getHoursFromSeconds } from "../../../utils/dates";

function HoursChart({ total_hours, total_over_time }) {
  if (!total_hours || !total_over_time) {
    return null;
  }

  const [totalHours, totalMinutes] = total_hours.split(":");
  const [totalHoursOvertime, totalMinutesOvertime] = total_over_time.split(":");

  const getSecondsFromHours = (hours) => Number(hours) * 60 * 60;
  const getSecondsFromMinutes = (minutes) => Number(minutes) * 60;

  const totalSeconds =
    getSecondsFromHours(totalHours) + getSecondsFromMinutes(totalMinutes);
  const totalSecondsOvertime =
    getSecondsFromHours(totalHoursOvertime) +
    getSecondsFromMinutes(totalMinutesOvertime);

  let data = [
    {
      id: "Godziny podstawowe",
      label: "Godziny podstawowe",
      value: getHoursFromSeconds(totalSeconds),
      color: "hsl(116, 70%, 50%)",
    },
    {
      id: "Nadgodziny",
      label: "Nadgodziny",
      value: getHoursFromSeconds(totalSecondsOvertime),
      color: "hsl(162, 70%, 50%)",
    },
  ];
  data = data.filter((item) => item.value > 0);

  return (
    <ResponsivePie
      data={data}
      colors={{ scheme: "paired" }}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    />
  );
}

export default HoursChart;
