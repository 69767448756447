import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    CircularProgress,
} from "@material-ui/core";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {validateFileExtension} from "../../../utils/FileValidatior";
import "./../Invoices/Invoices.css";
import {uploadNewContractAttachment} from "../../../services/contractsService";

function UploadContractAttachmentsDialog(props) {
    const [files, setFiles] = useState([]);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const {open, handleClose, uploadId, setIsEditable, setAttachmentAdded} = props;

    const validateAttachments = () => {
        let allValid = true;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!validateFileExtension(file.name, "pdf")) {
                toast.error("Dozwolone tylko pliki .pdf!");
                allValid = false;
                break;
            }
        }

        return allValid;
    };

    const uploadAttachments = async () => {
        try {
            if (!validateAttachments()) {
                return;
            }

            setRequestInProgress(true);

            const promisesArray = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileForm = new FormData();
                fileForm.append("file", file);
                fileForm.append("name", file.name);
                fileForm.append("contract", uploadId);
                promisesArray.push(uploadNewContractAttachment(fileForm));
            }

            await Promise.all(promisesArray);
            toast.success(files.length > 1 ? "Dodano pliki." : "Dodano plik.");
            setIsEditable(false);
            setAttachmentAdded(true);
            handleClose(true);
        } catch (error) {
            console.log(error);
        } finally {
            setRequestInProgress(false);

        }
    };

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
        fileNames.push(files[i].name);
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Przesyłanie plików</DialogTitle>
            <DialogContent>
                <input
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    className="attachments-upload-input"
                />
                {fileNames.length > 0 &&
                    fileNames.map((fileName, index) => (
                        <Grid item key={fileName + index}>
                            {fileName}
                        </Grid>
                    ))}
                <Grid container justify="center">
                    {requestInProgress && <CircularProgress/>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose(false)}
                    color="primary"
                    disabled={requestInProgress}
                >
                    Anuluj
                </Button>
                <Button
                    onClick={uploadAttachments}
                    color="primary"
                    disabled={requestInProgress}
                >
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadContractAttachmentsDialog;
