
export const defaultColumns = [
    {
        title: 'Nazwa',
        field: 'name'
    },
    {
        title: 'Akcje',
        field: 'id'
    }
];
