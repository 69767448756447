import React from 'react';
import MaterialTable from 'material-table';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const tableTheme = createMuiTheme({
	overrides: {
		MuiTableCell: {
			root: {
				padding: 8,
				fontFamily: [ 'Montserrat', 'Roboto', 'Arial', 'sans-serif' ].join(',')
			}
		},
		MuiTableRow: {
			root: {
				'&:nth-child(even)': {
					background: '#eaeaea'
				}
			}
		}
	}
});

function DefaultTable({ ...props }) {
	return (
		<MuiThemeProvider theme={tableTheme}>
			<MaterialTable
				title=""
				style={{ minWidth: '80%' }}
				localization={{
					body: {
						emptyDataSourceMessage: 'Brak rekordów do wyświetlenia',
						deleteTooltip: 'Usuń',
						editRow: {
							deleteText: 'Jesteś pewny, że chcesz usunąć zdarzenie?',
							saveTooltip: 'Zapisz',
							cancelTooltip: 'Anuluj',
						}
					},
					toolbar: {
						searchPlaceholder: 'Szukaj',
						searchTooltip: 'Szukaj',
						nRowsSelected: '{0} zaznaczonych wiersz(y)'
					},
					pagination: {
						labelRowsSelect: 'wierszy',
						labelDisplayedRows: '{from}-{to} z {count}',
						firstAriaLabel: 'Pierwsza',
						firstTooltip: 'Pierwsza',
						previousAriaLabel: 'Poprzednia',
						previousTooltip: 'Poprzednia',
						nextAriaLabel: 'Następna',
						nextTooltip: 'Następna',
						lastAriaLabel: 'Ostatnia',
						lastTooltip: 'Ostatnia'
					},
					header: {
						actions: 'Akcje'
					}
				}}
				options={{
					actionsColumnIndex: props.columns.length,
					pageSize: 10,
					pageSizeOptions: [ 10, 15, 20 ],
					// draggable: true
				}}
				{...props}
			/>
		</MuiThemeProvider>
	);
}

export default DefaultTable;
