import axios from "axios";
import { API } from "../constants";

export const getMonthTimeStats = (month, year) =>
  axios.get(`${API.STATISTICS}monthtime/month/${month}/year/${year}`);

export const getTimeDepartmentsStats = (month, year) =>
  axios.get(`${API.STATISTICS}timedepartments/month/${month}/year/${year}`);

export const getTimeProjectsStats = (month, year) =>
  axios.get(`${API.STATISTICS}timeprojects/month/${month}/year/${year}/`);

export const getEmployeesNumber = () =>
  axios.get(`${API.STATISTICS}employeesnumber/`);

export const getHighestLowestTime = (month, year) =>
  axios.get(`${API.STATISTICS}highestlowesttime/month/${month}/year/${year}/`);
