import React from "react";
import DefaultTable from "../../common/DefaultTable";

function ReaderTable({ readers }) {
  const columns = [
    {
      title: "ID",
      field: "id"
    },
    {
      title: "Nazwa czytnika",
      field: "reader_name"
    },
    {
      title: "Adres IP",
      field: "reader_ip"
    },
    {
      title: "N",
      field: "reader_latitude"
    },
    {
      title: "E",
      field: "reader_longitude"
    }
  ];

  return (
    <DefaultTable
      columns={columns}
      data={readers}
      title=""
      style={{ minWidth: "80%" }}
    />
  );
}

export default ReaderTable;
