import { Dialog, DialogTitle, DialogContent,
	 	Grid, DialogActions, Button,
	  	Select as MuiSelect, MenuItem, Input,
		InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Select from '../../common/Select';

function AssignManager({ open, handleClose, saveProject, project, managers }) {
	const [ newProject, setNewProject ] = useState({ ...project });

	useEffect(
		() => {
			setNewProject({ ...project });
		},
		[ project ]
	);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewProject({ ...newProject, [name]: value });
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Przypisz kierownika</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} justify="space-between" direction="column">
					<Grid item>
						<Select
							onChange={handleInputChange}
							name="manager"
							label="Kierownik"
							value={newProject.manager || ''}
							items={managers}
							itemValueProp="worker_id"
							customLabel={(item) => `${item.first_name} ${item.last_name}`}
						/>
					</Grid>
					<Grid item>
						<InputLabel>Kierownicy poboczni</InputLabel>
						<MuiSelect
							fullWidth
							label="Kierownicy poboczni"
							labelId="side_managers_label"
							id="side_manager"
							multiple
							value={newProject.side_manager || []}
							onChange={handleInputChange}
							name="side_manager"
							input={<Input />}
							>
							{managers && managers.map((item, idx) => (
								<MenuItem key={item["worker_id"]} value={item["worker_id"]}>
									{`${item.first_name} ${item.last_name}`}
								</MenuItem>
							))}
						</MuiSelect>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Anuluj
				</Button>
				<Button onClick={() => saveProject(newProject)} color="primary">
					Zapisz
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AssignManager;
