export const parseFloatWithComma = number => {
    if(number === null) return null;
    if(typeof number === 'string') {
        const replacedCommas = number.replace(/,/, '.');
        const parsedtoFloat = parseFloat(replacedCommas);
        return parseFloat((parsedtoFloat).toFixed(2));
    } else {
        return parseFloat((number).toFixed(2));
    }
}

