import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import NavIgationWithDrawer from './NavIgationWithDrawer';
import {Switch, Route} from 'react-router-dom';
import RCP from '../RCP/BZ/MonthlyTable';
import {logout} from '../../services/authenticationService';
import BZ from '../RCP/BZ/BZ';
import Administration from '../RCP/Administration/Administration';
import Projects from '../RCP/Budowy/Projects';
import Workers from '../RCP/Workers/Workers';
import WorkerDetails from '../RCP/Workers/WorkerDetails';
import Worklog from '../RCP/Worklog/Worklog';
import AbsenceCalendar from '../RCP/Calendar/AbsenceCalendar';
import PeopleOnline from '../RCP/PeopleOnline/PeopleOnline';
import Statistics from '../RCP/Statistics/Statistics';
import {getCurrentUser} from '../../services/userService';
import Invoices from '../RCP/Invoices/Invoices';
import Budgets from '../RCP/Budgets/Budgets';
import BudgetDetails from '../RCP/Budgets/BudgetDetails';
import BudgetOverview from '../RCP/Budgets/BudgetOverview';
import Contractors from "../RCP/Contractors/Contractors";
import InvoicesSales from "../RCP/InvoicesSales/Invoices";
import Contracts from "../RCP/Contracts/Contracts";

export const Routes = (applications) => (
    <Switch>
        <Route exact path="/rcp">
            <RCP/>
        </Route>
        <Route exact path="/bz">
            <BZ/>
        </Route>
        <Route exact path="/administracja">
            <Administration/>
        </Route>
        <Route exact path="/budowy">
            <Projects/>
        </Route>
        <Route exact path="/pracownicy">
            <Workers/>
        </Route>
        <Route exact path="/pracownicy/:userId">
            <WorkerDetails/>
        </Route>
        <Route exact path="/czaspracy">
            <Worklog/>
        </Route>
        <Route exact path="/kalendarz">
            <AbsenceCalendar/>
        </Route>
        <Route exact path="/pracownicy-online">
            <PeopleOnline/>
        </Route>
        <Route exact path="/statystyki">
            <Statistics/>
        </Route>
        <Route exact path="/faktury">
            <Invoices/>
        </Route>
        <Route exact path="/fakturysprzedaz">
            <InvoicesSales/>
        </Route>
        <Route exact path="/faktury/:budgetId/:invoiceType">
            <Invoices/>
        </Route>
        {/*<Route exact path="/faktury/:budgetId/sales">*/}
        {/*    <InvoicesSales/>*/}
        {/*</Route>*/}
        <Route exact path="/budzety">
            <Budgets/>
        </Route>
        <Route exact path="/budzety/details/:budgetId">
            <BudgetDetails/>
        </Route>
        <Route exact path="/budzety/overview/:budgetId">
            <BudgetOverview/>
        </Route>
        <Route exact path="/umowy">
            <Contracts/>
        </Route>
        <Route exact path="/umowy/:budgetId">
            <Contracts/>
        </Route>
        <Route exact path="/kontrahenci">
            <Contractors/>
        </Route>
    </Switch>
);

function Navigation(props) {
    const {pathname} = props.location;
    const [currentUser, setCurrentUser] = useState({});
    const fetchCurrentUser = async () => {
        try {
            // const s = s.x;
            const response = await getCurrentUser();
            const user = response.data;
            setCurrentUser(user);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
            props.history.push('/');
        }
    };


    useEffect(() => {
        fetchCurrentUser();
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
            props.history.push('/');
            window.reload();
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    if (!currentUser || Object.values(currentUser).length < 0) {
        return null;
    }

    return <NavIgationWithDrawer handleLogout={handleLogout} currentUser={currentUser}/>;
}

export default withRouter(Navigation);
