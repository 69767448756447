import React from "react";
import Button from "@material-ui/core/Button";
import { Popover, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  },
  popover: {
    overflow: "hidden"
  }
}));

function CustomPopover({ buttonText, children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        color="primary"
        style={{ fontSize: "1.5em" }}
      >
        {buttonText}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        PaperProps={{ style: { overflow: "hidden", padding: 22 } }}
      >
        {children}
      </Popover>
    </div>
  );
}

export default CustomPopover;
