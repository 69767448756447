import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
    TextField,
    DialogContentText
} from '@material-ui/core';
import Select from '../../common/Select';
import { INVOICE_STAGE } from '../../common/invoiceStages';

const AssignInvoiceDialog = ({ open, handleClose, handleSubmit, invoiceId, budgets, budgetToAssign, chosenPrice, maxPossiblePrice, remainingBudget }) => {


    const [invoiceAssignmentObject, setInvoiceAssignmentObject] = useState({				
        invoice: invoiceId,
        stage: INVOICE_STAGE.SENT_TO_REVIEW,
        price: remainingBudget
    });

    const handleInvoiceObjectChange = (event) => {
        const {name, value} = event.target;

        if(invoiceAssignmentObject[name] !== null) {
            invoiceAssignmentObject[name] = value;
            setInvoiceAssignmentObject(
               invoiceAssignmentObject);
        }

        setInvoiceAssignmentObject({
            [name]: value,
            ...invoiceAssignmentObject
        });
    }

    const handleSubmitInternal = () => {
        handleSubmit(invoiceAssignmentObject);
        setInvoiceAssignmentObject({
            stage: INVOICE_STAGE.SENT_TO_REVIEW,
            code_number: null
        });
    }

    const handleCloseInternal = () => {
        handleClose();
        setInvoiceAssignmentObject({
            stage: INVOICE_STAGE.SENT_TO_REVIEW,
        });
    }

    useEffect(() => {
        setInvoiceAssignmentObject({				
            invoice: invoiceId,
            stage: INVOICE_STAGE.SENT_TO_REVIEW,
            price: remainingBudget
        })
    }, [remainingBudget])
 
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'md'}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">Przypisz budżet</DialogTitle>
			<DialogContent>
            <DialogContentText>
                Pozostała kwota do przypisania {remainingBudget}zł.
            </DialogContentText>
                <Grid container justify="flex-start" spacing={2} alignItems="flex-end">
                    <Grid item xs={6}>
                        {budgets && budgets.length > 0 ? (
                            <Select
                                required
                                onChange={handleInvoiceObjectChange}
                                label="Budżet"
                                value={invoiceAssignmentObject["budget"]}
                                items={budgets}
                                itemValueProp="id"
                                itemLabelProp="name"
                                name="budget"
                            />
                        ) : 
                        <div>Brak budżetów</div>}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Kwota"
                            value={invoiceAssignmentObject["price"] }
                            name="price"
                            onChange={handleInvoiceObjectChange}
                            type="number"
                        />
                    </Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmitInternal} color="primary">
					Przypisz
				</Button>
				<Button onClick={handleCloseInternal} color="primary">
					Anuluj
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AssignInvoiceDialog;
