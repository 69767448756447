import axios from "axios";
import { API } from "../constants";

export const getUsers = async () => {
  try {
    const response = await axios.get(API.USER);
    return response.data;
  } catch (error) {
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};
export const getRoles = async () => axios.get(API.GROUP);

export const addUser = async (user) => {
  try {
    await axios.post(API.USER, user);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const addWorker = async (worker) => {
  try {
    await axios.post(API.PEOPLE, worker);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const updateWorker = async (worker, workerId) => {
  try {
    await axios.put(`${API.PEOPLE}id/${workerId}/`, worker);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getWorkers = (active) =>
  axios.get(`${API.PEOPLE}active/${active}/`);

export const getPeople = (active) =>
    axios.get(`${API.PEOPLE}active/${active}/nocache/`);

export const getUser = async (userId) =>
  axios.get(`${API.PEOPLE}id/${userId}/`);

export const getManagers = () => axios.get(`${API.ROLES}manager/`);

export const getWorkerCalendar = (id, month, year) =>
  axios.get(`${API.CALENDAR}id/${id}/month/${month}/year/${year}/`);

export const getWorkerProjects = (id, month, year) =>
  axios.get(`${API.CALENDAR}id/${id}/month/${month}/year/${year}/project/`);

export const getCurrentUser = () => axios.get(`${API.USER}currentuser/`);

export const getOnlineUsers = () => axios.get(`${API.PEOPLE_ONLINE}`);

export const getUsersWithRoles = () => axios.get(`${API.GROUP}all/`);

export const getWorkersInProject = (projectId) =>
  axios.get(`${API.PEOPLE}project/${projectId}/`);


export const getApplications = () => axios.get(`${API.LICENSE}`);