import React from "react";
import { Tooltip, IconButton, Icon } from "@material-ui/core";

function TooltipIconButton({ title = "", iconName = "", onClick }) {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick} style={{ padding: 0 }}>
        <Icon>{iconName}</Icon>
      </IconButton>
    </Tooltip>
  );
}

export default TooltipIconButton;
