import React from "react";
import DefaultTable from "../../common/DefaultTable";

function AccessZoneTable({ zones }) {
  const columns = [
    {
      title: "ID",
      field: "id"
    },
    {
      title: "Nazwa strefy",
      field: "zone"
    },
    {
      title: "Czytnik",
      field: "reader"
    }
  ];

  return (
    <DefaultTable
      columns={columns}
      data={zones}
      title=""
      style={{ minWidth: "80%" }}
    />
  );
}

export default AccessZoneTable;
