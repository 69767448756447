import axios from 'axios';
import { API } from '../constants';

export const login = async (form) => {
	try {
		removeTokenFromStorage();
		const response = await axios.post(API.AUTH, form);
		const { token } = response.data;
		saveTokenToLocalStorage(token);
		return;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const logout = async () => {
	try {
		removeTokenFromStorage();
		return true;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

const saveTokenToLocalStorage = (token) => {
	localStorage.setItem('token', token);
};

const removeTokenFromStorage = () => {
	localStorage.removeItem('token');
};

export const getToken = () => localStorage.getItem('token');
