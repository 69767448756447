import { API } from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';

export const getContractors = async () => {
	try {
		const response = axios.get(`${API.CONTRACTOR}`);
		return response;
	} catch (error) {
		console.log(error);
		// toast.error('Coś poszło nie tak.');
		throw error;
	}
};

export const createContractor = async (newContractor) => {
	try {
		await axios.post(`${API.CONTRACTOR}`, newContractor);
		toast.success("Kontrahent utworzony!")
	} catch (error) {
		console.log(error);
		// toast.error('Coś poszło nie tak.');
		throw error;
	}
};

export const contractorDelete = async (id) => {
	try {
		await axios.post(`${API.CONTRACTOR}delete/${id}/`);
		toast.success("Kontrahent usunięty!")
	} catch (error) {
		console.log(error);
		// toast.error('Coś poszło nie tak.');
		throw error;
	}
}

export const contractorEdit = async (id, data) => {
	try {
		await axios.put(`${API.CONTRACTOR}${id}/`, data);
		toast.success("Kontrahent edytowany!")
	} catch (error) {
		console.log(error);
		// toast.error('Coś poszło nie tak.');
		throw error;
	}
}
