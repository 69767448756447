import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import DatePicker from "../../common/DatePicker";
import { makeStyles } from "@material-ui/styles";
import WeekPicker from "./WeekPicker";
import Tile from "../../common/Tile";

const useStyles = makeStyles(theme => ({
  controlButton: {
    padding: 20,
    fontSize: "1.2em"
  },
  buttonTitle: {
    paddingBottom: 15
  },
  halfSize: {
    width: "48%"
  }
}));

function Control(props) {
  const classes = useStyles();
  const { label, selected, setSelected, id } = props;
  return (
    <Button
      variant={selected === id ? "contained" : "outlined"}
      color="primary"
      fullWidth
      className={classes.controlButton}
      onClick={() => setSelected(id)}
    >
      <Grid container direction="column">
        <Grid item className={classes.buttonTitle}>
          {label}
        </Grid>
        <Grid item>{props.children}</Grid>
      </Grid>
    </Button>
  );
}

function Controls({ setSelected, selected, onDateChange, date }) {
  const classes = useStyles();
  const MonthTileContent = (
    <>
      <Grid item>
        <Typography variant="h5">Widok Miesięczny</Typography>
      </Grid>
      <Grid item>
        <DatePicker
          views={["month", "year"]}
          value={date["month"]}
          onChange={date => onDateChange("month", date)}
        />
      </Grid>
    </>
  );
  const WeekTileContent = (
    <>
      <Grid item>
        <Typography variant="h5">Widok Tygodniowy</Typography>
      </Grid>
      <Grid item>
        <WeekPicker onDateChange={date => onDateChange("week", date)} />
      </Grid>
    </>
  );
  const FromToTileContent = (
    <>
      <Grid item>
        <Typography variant="h5">Widok Od-Do</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="space-between">
          <Grid item className={classes.halfSize}>
            <DatePicker
              value={date["rangeFrom"]}
              onChange={date => onDateChange("rangeFrom", date)}
            />
          </Grid>
          <Grid item className={classes.halfSize}>
            <DatePicker
              value={date["rangeTo"]}
              onChange={date => onDateChange("rangeTo", date)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid item container spacing={3}>
      <Grid item sm={4}>
        <Tile
          onClick={() => setSelected(0)}
          isActive={selected === 0}
          content={MonthTileContent}
        />
      </Grid>
      <Grid item sm={4}>
        <Tile
          onClick={() => setSelected(1)}
          isActive={selected === 1}
          content={WeekTileContent}
        />
      </Grid>
      <Grid item sm={4}>
        <Tile
          onClick={() => setSelected(2)}
          isActive={selected === 2}
          content={FromToTileContent}
        />
      </Grid>
    </Grid>
  );
}

export default Controls;
