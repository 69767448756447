import React, {useState, useEffect} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Grid,
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import {Document, Page, pdfjs} from "react-pdf";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import DatePicker from "../../common/DatePicker";
import Select from "../../common/Select";
import {getContractors} from "../../../services/contractorService";
import {CONTRACTS_TILES} from "../../common/TileTypes";
import moment from "moment";
import {
    getAllDeposits, getContractAttachmentPDF,
    getContractPDF,
    uploadNewContractDetails,
    updateContractDetails
} from "../../../services/contractsService";
import {getBudgets} from "../../../services/budgetService";
import UploadContractAttachmentsDialog from "./UploadContractAttachmentsDialog";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FillContractDialog = ({
                                open,
                                setOpen,
                                handleClose,
                                invoiceName,
                                contractData,
                                id,
                                isEditable,
                                activeTile,
                            }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filledData, setFilledData] = useState(contractData || {});
    const [contractors, setContractors] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [deposits, setDeposits] = useState([]);
    const [currentFileId, setCurrentFileId] = useState(null);
    const [allFiles, setAllFiles] = useState({});
    const [dataEditable, setDataEditable] = useState(!isEditable);
    const [attachmentAdded, setAttachmentAdded] = useState(false);
    const [openAddAttachment, setOpenAddAttachment] = useState(false);
    const deposit_types = [{id: 'net', name: 'Netto'}, {id: 'gross', name: 'Brutto'}]

    const closeUploadAttachmentsDialog = (shouldFetch = false) => {
        setOpenAddAttachment(false);
    };

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const {register, errors, handleSubmit, control} = useForm({
        mode: "onChange",
    });


    const onSubmit = async () => {
        try {
            let picked = {};
            if (activeTile === CONTRACTS_TILES.FILE_LIST) {
                picked = (({
                               number,
                               date,
                               contractor,
                               budget,
                               deposit,
                               deposit_type,
                               tranche,
                               second_deposit,
                               second_deposit_type,
                               end_date,
                               warranty_date,
                    second_warranty_date,
                               contract_file,
                               comment
                           }) => ({
                    number,
                    date,
                    contractor,
                    budget,
                    deposit,
                    deposit_type,
                    tranche,
                    second_deposit,
                    second_deposit_type,
                    end_date,
                    warranty_date,
                    second_warranty_date,
                    contract_file,
                    comment
                }))(filledData);
                picked.comment = filledData.comment;
                picked.budget = filledData.budget;
                picked.contract_file = id;
                picked.contractor = filledData.contractor;
                picked.deposit = filledData.deposit;
                picked.deposit_type = filledData.deposit_type;
                picked.tranche = filledData.tranche;
                picked.second_deposit = filledData.second_deposit;
                picked.second_deposit_type = filledData.second_deposit_type;
                picked.date = moment(picked.date).format("YYYY-MM-DD");
                picked.end_date = moment(picked.end_date).format("YYYY-MM-DD");
                picked.warranty_date = moment(picked.warranty_date).format("YYYY-MM-DD");
                picked.second_warranty_date = moment(picked.second_warranty_date).format("YYYY-MM-DD");
            } else {
                picked = (({
                               number,
                               budget,
                               contractor,
                               deposit,
                               deposit_type,
                               tranche,
                               second_deposit,
                               second_deposit_type,
                               date,
                               end_date,
                               warranty_date,
                    second_warranty_date
                           }) => ({
                    number,
                    budget,
                    contractor,
                    deposit,
                    deposit_type,
                    tranche,
                    second_deposit,
                    second_deposit_type,
                    date,
                    end_date,
                    warranty_date,
                    second_warranty_date
                }))(
                    filledData
                );
                picked.date = moment(picked.date).format("YYYY-MM-DD");
                picked.end_date = moment(picked.end_date).format("YYYY-MM-DD");
                picked.warranty_date = moment(picked.warranty_date).format("YYYY-MM-DD");
                picked.second_warranty_date = moment(picked.second_warranty_date).format("YYYY-MM-DD");
            }
            // picked.due_date = moment(filledData.due_date).format("YYYY-MM-DD");
            if (Object.values(picked).every((entry) => isNotEmptyString(entry))) {
                if (activeTile === CONTRACTS_TILES.FILE_LIST) {
                    await uploadNewContractDetails(picked);
                } else {
                    await updateContractDetails(picked, filledData.id).then((response) => {
                        if (response.status === 200) {
                            toast.success("Zaktualizowano.");
                        } else {
                            toast.error(response.statusText);
                        }

                    })
                }
                handleClose(true);
            } else {
                toast.error("Nieprawidłowo wypełniony formularz.");
            }
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    const isNotEmptyString = (string) =>
        /\S/.test(string) && string !== undefined && string !== null;

    useEffect(() => {
        setFilledData({...filledData, ["contract_id"]: id + ""});
    }, [id]);

    const addFile = (id, file) => {
        setAllFiles({...allFiles, [id]: file});
    };

    const fetchContractPDF = async (id) => {
        try {
            const fileExist = allFiles[id];
            if (id && !fileExist) {
                const inv = await getContractPDF(id);
                addFile(id, inv);
            }
            setPageNumber(1);
            setCurrentFileId(id);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAttachmentPDF = async (id) => {
        try {
            const fileExist = allFiles[id];
            if (id && !fileExist) {
                const attachment = await getContractAttachmentPDF(id);
                addFile(id, attachment);
            }
            setPageNumber(1);
            setCurrentFileId(id);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchContractors = async () => {
        try {
            const contractors = await getContractors();
            setContractors(contractors.data);
        } catch (error) {
            console.log(error);
            toast.error("Błąd pobierania Kontrahentów");
        }
    };

    const fetchBudgets = async () => {
        try {
            const budgets = await getBudgets();
            setBudgets(budgets.data);
        } catch (error) {
            console.log(error);
            toast.error("Błąd pobierania budżetów");
        }
    };

    const fetchDeposits = async () => {
        try {
            const deposits = await getAllDeposits();
            setDeposits(deposits);
        } catch (error) {
            console.log(error);
            toast.error("Błąd pobierania Kaucji");
        }
    };


    useEffect(() => {
        setDataEditable(!isEditable);
        if (id !== null) {
            fetchContractPDF(id);
            setFilledData(contractData);
            fetchContractors();
            fetchBudgets();
            fetchDeposits();
        }
        return () => {
            setAllFiles({});
            setFilledData({});
        };
    }, [open]);

    useEffect(() => {
        if (filledData && filledData.invoice_number) {
            filledData.number = filledData.invoice_number;
        }
    }, [filledData]);

    const handleChange = (name, value) => {
        setFilledData({...filledData, [name]: value});
    };

    const file = allFiles[currentFileId];

    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                fullWidth={true}
                maxWidth={"lg"}
                key={dataEditable}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    Umowa {filledData && filledData.number} -{" "}
                    {filledData && filledData.name}
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"space-evenly"}
                        alignItems="flex-start"
                    >
                        <Grid item xs={9}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                // tabIndex={-1}
                                component="div"
                                style={{minHeight: "1000px"}}
                            >
                                {file && (
                                    <Document
                                        file={`data:application/pdf;base64,${file}`}
                                        loading={"Trwa ładowanie pliku..."}
                                        noData={"Brak pliku."}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        onLoadError={(e) =>
                                            console.error("Plik nie został załadowany poprawnie ", e)
                                        }
                                    >
                                        <Page pageNumber={pageNumber} scale={1.3}/>
                                        <div>
                                            <p>
                                                Strona {pageNumber || (numPages ? 1 : "--")} z{" "}
                                                {numPages || "--"}
                                            </p>
                                            <button
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                            >
                                                Poprzednia
                                            </button>
                                            <button
                                                type="button"
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                            >
                                                Następna
                                            </button>
                                        </div>
                                    </Document>
                                )}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={3} style={{position: "sticky", top: 0}}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container item spacing={3} direction={"column"}>
                                    <Grid item xs={12}>
                                        <TextField
                                            inputRef={register({required: "Pole nie może być puste"})}
                                            autoFocus
                                            label="NR Umowy"
                                            value={filledData && filledData.number}
                                            name="number"
                                            onChange={(e) => handleChange("number", e.target.value)}
                                            error={Boolean(errors["number"])}
                                            helperText={errors["number"] && errors["number"].message}
                                            disabled={dataEditable}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            value={filledData && filledData.date}
                                            label={'Data umowy'}
                                            onChange={(value) => handleChange("date", value)}
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*<Select*/}
                                        {/*    onChange={(e) =>*/}
                                        {/*        handleChange("deposit", e.target.value)*/}
                                        {/*    }*/}
                                        {/*    label="Kaucja"*/}
                                        {/*    value={filledData && filledData.deposit}*/}
                                        {/*    name={"deposit"}*/}
                                        {/*    items={deposits}*/}
                                        {/*    itemValueProp="id"*/}
                                        {/*    itemLabelProp="value"*/}
                                        {/*    disabled={dataEditable}*/}
                                        {/*/>*/}
                                        <TextField
                                            label={"Kaucja"}
                                            value={filledData && filledData.deposit}
                                            onChange={(e) => handleChange("deposit", e.target.value)}
                                            disabled={dataEditable}
                                            name={"deposit"}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            onChange={(e) =>
                                                handleChange("deposit_type", e.target.value)
                                            }
                                            label="Kaucja Netto/Brutto"
                                            value={filledData && filledData.deposit_type}
                                            name={"deposit_type"}
                                            items={deposit_types}
                                            itemValueProp="id"
                                            itemLabelProp="name"
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            inputRef={register({required: "Pole nie może być puste"})}
                                            label="Transza 1"
                                            value={filledData && filledData.tranche}
                                            name="tranche"
                                            onChange={(e) => handleChange("tranche", e.target.value)}
                                            error={Boolean(errors["tranche"])}
                                            helperText={errors["tranche"] && errors["tranche"].message}
                                            disabled={dataEditable}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={dataEditable ? "Transza 2" : ""}
                                            value={filledData && filledData.tranche ? 100 - filledData.tranche : null}
                                            // onChange={(e) => handleChange("tranche", e.target.value)}
                                            disabled={true}
                                            InputProps={{
                                                placeholder: 'Transza 2',
                                                readOnly: true,
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*<Select*/}
                                        {/*    onChange={(e) =>*/}
                                        {/*        handleChange("second_deposit", e.target.value)*/}
                                        {/*    }*/}
                                        {/*    label="GW"*/}
                                        {/*    value={filledData && filledData.second_deposit}*/}
                                        {/*    name={"second_deposit"}*/}
                                        {/*    items={deposits}*/}
                                        {/*    itemValueProp="id"*/}
                                        {/*    itemLabelProp="value"*/}
                                        {/*    disabled={dataEditable}*/}
                                        {/*/>*/}
                                        <TextField
                                            label={"GW"}
                                            value={filledData && filledData.second_deposit}
                                            onChange={(e) => handleChange("second_deposit", e.target.value)}
                                            disabled={dataEditable}
                                            name={"second_deposit"}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            onChange={(e) =>
                                                handleChange("second_deposit_type", e.target.value)
                                            }
                                            label="GW Netto/Brutto"
                                            value={filledData && filledData.second_deposit_type}
                                            name={"second_deposit_type"}
                                            items={deposit_types}
                                            itemValueProp="id"
                                            itemLabelProp="name"
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {contractors && contractors.length !== 0 ? (
                                            <Select
                                                onChange={(e) =>
                                                    handleChange("contractor", e.target.value)
                                                }
                                                label="Kontrahent"
                                                value={filledData && filledData.contractor}
                                                name={"contractor"}
                                                items={contractors}
                                                itemValueProp="id"
                                                itemLabelProp="name"
                                                disabled={dataEditable}
                                            />
                                        ) : (
                                            <div>Najpierw dodaj kontrahenta!</div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {budgets && budgets.length !== 0 ? (
                                            <Select
                                                onChange={(e) =>
                                                    handleChange("budget", e.target.value)
                                                }
                                                label="Budżet"
                                                value={filledData && filledData.budget}
                                                name={"budget"}
                                                items={budgets}
                                                itemValueProp="id"
                                                itemLabelProp="name"
                                                disabled={dataEditable}
                                            />
                                        ) : (
                                            <div>Najpierw dodaj budżet!</div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Inne obciążenia?"
                                            value={filledData && filledData.comment}
                                            name="comment"
                                            onChange={(e) => handleChange("comment", e.target.value)}
                                            multiline={true}
                                            rows={4}
                                            fullWidth
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            value={
                                                filledData && filledData.end_date
                                                    ? filledData.end_date
                                                    : null
                                            }
                                            label={'Termin zakonczenia'}
                                            onChange={(value) => handleChange("end_date", value)}
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            value={
                                                filledData && filledData.warranty_date
                                                    ? filledData.warranty_date
                                                    : null
                                            }
                                            label={'Data gwarancji'}
                                            onChange={(value) => handleChange("warranty_date", value)}
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            value={
                                                filledData && filledData.second_warranty_date
                                                    ? filledData.second_warranty_date
                                                    : null
                                            }
                                            label={'Druga data gwarancji'}
                                            onChange={(value) => handleChange("second_warranty_date", value)}
                                            disabled={dataEditable}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        Pliki:
                                    </Grid>
                                    {filledData && filledData.number && (
                                        <Grid item
                                              container
                                              xs={12}
                                              style={{flexWrap: "nowrap"}}
                                        >
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                style={{width: "100%", wordBreak: "break-word"}}
                                                onClick={() =>
                                                    fetchContractPDF(filledData.contract_file)
                                                }
                                            >
                                                {filledData.number}
                                            </Button>
                                            {/*<IconButton*/}
                                            {/*    color="primary"*/}
                                            {/*    size="small"*/}
                                            {/*    onClick={() => downloadInvoicePDF(filledData.invoice_file_id)}*/}
                                            {/*>*/}
                                            {/*    <GetAppIcon/>*/}
                                            {/*</IconButton>*/}
                                        </Grid>
                                    )}
                                    {filledData &&
                                    filledData.contract_attachment_file ?
                                        <Grid
                                            xs={12}
                                            container
                                            item
                                            style={{flexWrap: "nowrap"}}
                                        >
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                style={{width: "100%", wordBreak: "break-word"}}
                                                onClick={() => fetchAttachmentPDF(filledData.contract_attachment_file.id)}
                                            >
                                                {filledData.contract_attachment_file.name}
                                            </Button>
                                            {/*<IconButton*/}
                                            {/*    color="primary"*/}
                                            {/*    size="small"*/}
                                            {/*    onClick={() => downloadAttachmentPDF(filledData.contract_attachment_file.id)}*/}
                                            {/*>*/}
                                            {/*    <GetAppIcon/>*/}
                                            {/*</IconButton>*/}
                                        </Grid>

                                        : <></>
                                    }
                                    <Grid item xs={12}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            style={{width: "100%", wordBreak: "break-word"}}
                                            onClick={() => setOpenAddAttachment(true)}
                                        >
                                            Dodaj załącznik
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit} color="primary" disabled={dataEditable}>
                        Zapisz
                    </Button>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Anuluj
                    </Button>
                </DialogActions>
            </Dialog>
            <UploadContractAttachmentsDialog
                open={openAddAttachment}
                setIsEditable={setDataEditable}
                handleClose={closeUploadAttachmentsDialog}
                uploadId={filledData.id}
                setAttachmentAdded={setAttachmentAdded}
            />
        </>
    );
};

export default FillContractDialog;
