import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <KeyboardDatePicker {...props} />
    </MuiPickersUtilsProvider>
  );
}
