import React, {
    useState,
    useEffect,
    createRef,
    useCallback,
    useMemo,
} from "react";
import {Grid} from "@material-ui/core";
import ContractsTable from "./ContractsTable";
import {toast} from "react-toastify";
import Tile from "../../common/Tile";
import DescriptionIcon from "@material-ui/icons/Description";


import {
    uploadNewInvoice,
    assignInvoiceBudget,
    categoryAssign,
    deleteInvoice,
    downloadInvoiceReportList,
    uploadNewAttachment, getAllInvoices,
} from "../../../services/invoiceService";
import PostAddIcon from "@material-ui/icons/PostAdd";
import {FILE_TYPE, CONTRACTS_TILES, TILES} from "../../common/TileTypes";
import {
    getBudgets,
    getBudgetDetails,
} from "../../../services/budgetService";
import TooltipIconButton from "../../common/TooltipIconButton";
import {useLocation} from "react-router-dom";
import {adding} from "../../../static/styles/role_themes";
import {parseFloatWithComma} from "../../../utils/customNumberParser";
import GetApp from "@material-ui/icons/GetApp";
import {validateFileExtension} from "../../../utils/FileValidatior";
import CustomLoader from "../../common/CustomLoader";
import debounce from "lodash.debounce";
import {
    deleteContract,
    getAllContracts,
    getAllContractsFiles,
    getContractsForBudget,
    uploadNewContract
} from "../../../services/contractsService";
import ConfirmInvoiceDialog from "../Invoices/ConfirmInvoiceDialog";

function Contracts() {
    const [budgets, setBudgets] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [contractFile, setContractFile] = useState(null);
    const [confirmInvoiceDialog, setConfirmInvoiceDialog] = useState(null);
    const [activeTile, setActiveTile] = useState(CONTRACTS_TILES.ALL);
    const [budgetBasicData, setBudgetBasicData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const fileInput = createRef();
    const location = useLocation();

    const getBudgetIDFromPathname = () => {
        const budgetID = parseInt(location.pathname.split("/")[location.pathname.split("/").length - 2]);
        return isNaN(budgetID) ? null : budgetID;
    };
    const [selectedBudgetID, setSelectedBudgetID] = useState(getBudgetIDFromPathname);

    useEffect(() => {
        const path = location.pathname.split("/").pop();
        if (path === "umowy") {
            setActiveTile(CONTRACTS_TILES.ALL);
        } else {
            setActiveTile(null);
        }
        fetchBudgetDetails();
    }, [selectedBudgetID]);

    useEffect(() => {
        setSelectedBudgetID(getBudgetIDFromPathname());
    }, [location.pathname]);


    const contractsColumns = [
        {
            title: "Nr umowy",
            field: "number",
        },
        {
            title: "Kontrahent",
            field: "contractor_name",
        },
        {
            title: "Data umowy",
            field: "date",
        },
        {
            title: "Budżet",
            field: "budget_name",
        },

    ];

    const fileInvoiceColumns = [
        {
            title: "Nazwa",
            field: "name",
        },
        {
            title: "Plik",
            field: "invoice_file",
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Usuń"
                            iconName="delete"
                            onClick={() => handleDeleteContractFile(rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];


    const [chosenColumns, setChosenColumns] = useState(contractsColumns);
    const [selectedRow, setSelectedRow] = useState({});
    const [isOpenInvoiceDialog, setOpenInvoiceDialog] = useState(false);

    const fetchBudgetDetails = async () => {
        try {
            if (!selectedBudgetID) return;
            setIsLoaded(false);
            const response = await getBudgetDetails(selectedBudgetID);
            const fetchedBudgetDetails = response.data;
            setBudgetBasicData(fetchedBudgetDetails.budget);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        } finally {
            setIsLoaded(true);
        }
    };

    const handleDeleteContractFile = async (rowData) => {
        await deleteContract(rowData.id);
        debouncedFetchContracts();
    };

    const fetchBudgets = async (shouldHideLoader = true) => {
        setIsLoaded(false);
        try {
            const budgets = await getBudgets();
            setBudgets(budgets.data);
        } catch (e) {
            setBudgets([]);
        } finally {
            if (shouldHideLoader) {
                setIsLoaded(true);
            }
        }
    };

    const fetchContracts = useCallback(async () => {
        let contracts;
        const budgetId = selectedBudgetID;
        try {
            if (budgetId) {
                    contracts = await getContractsForBudget(budgetId);
            } else {
                switch (activeTile) {
                    case CONTRACTS_TILES.ALL:
                        contracts = await getAllContracts();
                        break;
                    case CONTRACTS_TILES.FILE_LIST:
                        contracts = await getAllContractsFiles();
                        break
                    default:
                        contracts = await getAllContracts();
                }

            }
            if (Array.isArray(contracts)) {
                setContracts(contracts);
            }

        } catch (e) {
            console.error(e);
            setContracts([]);
        } finally {
            setIsLoaded(true);
        }
        }
    )

    const debouncedFetchContracts = useMemo(
        () => debounce(fetchContracts, 300),
        [fetchContracts]
    );
    const [fileTypeSelected, setFileTypeSelected] = useState(null);

    const fileChange = (event, fileType, rowData) => {
        setContractFile(event.target.files[0]);

        if (rowData) {
            setSelectedRow(rowData);
        }
        if (!!event.target.files[0]) {
            if (fileType) {
                setFileTypeSelected(fileType);
            }
            setConfirmInvoiceDialog(true);
        }
    };

    const uploadFile = async () => {
        try {
            if (!validateFileExtension(contractFile.name, "pdf")) {
                toast.error("Dozwolone tylko pliki .pdf!");
                setContractFile(null);
                setConfirmInvoiceDialog(null);
                debouncedFetchContracts();
                return;
            }
            setIsLoaded(false);
            let fileForm = new FormData();
            fileForm.append("file", contractFile);
            fileForm.append("name", contractFile.name);

            if (fileTypeSelected === FILE_TYPE.CONTRACT) {
                await uploadNewContract(fileForm);
            } else if (fileTypeSelected === FILE_TYPE.ATTACHMENT) {
                fileForm.append("invoice_position_budget_id", selectedRow.id);
                await uploadNewAttachment(fileForm);
            } else {
                setContractFile(null);
                setConfirmInvoiceDialog(null);
                toast.error("Możliwe dodanie tylko załącznika lub faktury.");
                debouncedFetchContracts();
                return;
            }
            setContractFile(null);
            setConfirmInvoiceDialog(null);
            toast.success("Dodano plik.");
            debouncedFetchContracts();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoaded(true);
        }
    };



    const fetchBudgetAndInvoices = async () => {
        await fetchBudgets(false);
        if (activeTile !== null) {
            debouncedFetchContracts();
        } else if (selectedBudgetID !== null) {
            fetchContracts();
        }
    };

    useEffect(() => {
        fetchBudgetAndInvoices();

        switch (activeTile) {
            case CONTRACTS_TILES.FILE_LIST:
                setChosenColumns(fileInvoiceColumns);
                break;
            default:
                setChosenColumns(contractsColumns);
                break;
        }
    }, [activeTile]);


    return (
        <Grid container direction="column" spacing={2}>
            <CustomLoader loaded={isLoaded}/>
            {selectedBudgetID === null && (
                <Grid container item spacing={2}>
                    <Grid item>
                        <Tile
                            style={activeTile !== CONTRACTS_TILES.ALL ? adding : {}}
                            icon={<GetApp style={{fontSize: "3em"}}/>}
                            text={"Lista umów"}
                            onClick={() => setActiveTile(CONTRACTS_TILES.ALL)}
                            isActive={activeTile === CONTRACTS_TILES.ALL}
                            content={<EmptySummary/>}
                        />
                    </Grid>
                    <Grid item>
                        <label htmlFor="upload-file">
                            <input
                                ref={fileInput}
                                style={{display: "none"}}
                                id="upload-file"
                                name="upload-file"
                                type="file"
                                onChange={(event) => fileChange(event, FILE_TYPE.CONTRACT)}
                            />
                            <Tile
                                style={adding}
                                icon={<PostAddIcon style={{fontSize: "3em"}}/>}
                                text={"Dodaj umowę"}
                                content={<EmptySummary/>}
                            />
                            <ConfirmInvoiceDialog
                                open={Boolean(confirmInvoiceDialog)}
                                handleClose={setConfirmInvoiceDialog}
                                fileName={contractFile || ""}
                                saveDialog={() => uploadFile(FILE_TYPE.CONTRACT)}
                            />
                        </label>
                    </Grid>
                    <Grid item>
                        <Tile
                            style={activeTile !== CONTRACTS_TILES.FILE_LIST ? adding : {}}
                            icon={<DescriptionIcon style={{fontSize: "3em"}}/>}
                            text={"Pliki"}
                            onClick={() => setActiveTile(CONTRACTS_TILES.FILE_LIST)}
                            isActive={activeTile === CONTRACTS_TILES.FILE_LIST}
                            content={<EmptySummary/>}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <ContractsTable
                    contracts={contracts}
                    columns={chosenColumns}
                    activeTile={activeTile}
                    fetchContracts={fetchContracts}
                    setSelectedRow={setSelectedRow}
                    isOpenInvoiceDialog={isOpenInvoiceDialog}
                    setOpenInvoiceDialog={setOpenInvoiceDialog}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 15, 20],
                    }}
                />
            </Grid>

        </Grid>
    );
}


const EmptySummary = () => {
    return <Grid container direction="column" style={{height: 40}}></Grid>;
};

export default Contracts;
