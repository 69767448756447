import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DefaultTable from "../../common/DefaultTable";
import {deleteEvent} from "../../../services/worklogService";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const WorklogDialog = ({
                                  open,
                                  handleClose,
                                  workerData,
                                  events,
                                  setEvents,

                              }) => {
    let columns = [
        {
            title: "Data",
            field: "date_of_event",
            editable: "never",
        },
        {
            title: "Godzina",
            field: "time_of_event",
            editable: "never",
        },
        {
            title: "Typ zdarzenia",
            field: "event_type",
            lookup: { 0: 'Wejście', 1: 'Wyjście' },
        }
    ]
    const [eventsToDelete, setEventsToDelete] = useState([]);

    const deleteEvents = async () => {
        if (eventsToDelete.length) {
            for (let event of eventsToDelete) {
                await deleteEvent(event);
        }
        }

    }

    const handleCloseInternal = async () => {
        await deleteEvents();
        setEventsToDelete([]);
        handleClose();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="form-dialog-title">Zdarzenia pracownika {workerData.worker}.</DialogTitle>
            <DialogContent>
                <DefaultTable
                    columns={columns}
                    data={events}
                    options={{
                        search: false
                    }}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...events];
                                    const eventId = oldData.id;
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setEvents([...dataDelete]);
                                    setEventsToDelete([...eventsToDelete, eventId])
                                    resolve()
                                }, 1000)
                            }),
                    }}

                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseInternal} color="primary">
                    Zamknij
                </Button>
            </DialogActions>
        </Dialog>
    )
}