import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { INVOICE_STAGE } from "../../common/invoiceStages";
import {
  changeInvoiceStage,
  getAccountingCodes,
} from "../../../services/invoiceService";
import Select from "../../common/Select";
import { toast } from "react-toastify";
import { parseFloatWithComma } from "../../../utils/customNumberParser";

const AcceptPendingInvoiceDialog = ({
  open,
  handleClose,
  handleSubmit,
  invoiceId,
  fullPrice,
  budgetName,
  invoicePositionId,
}) => {
  const INVOICE_TYPE = 'sales';
  const [percentOfPrice, setPercentOfPrice] = useState(null);
  const [additionalComment, setAdditionalComment] = useState("");
  const [priceValue, setPriceValue] = useState(fullPrice);
  const [accountingCode, setAccountingCode] = useState(null);
  const [chosenAccoutningCode, setChosenAccoutningCode] = useState(null);

  const handlePercentChange = (event) => {
    let percent = event.target.value;
    if (percent > 100) {
      percent = 100;
    } else if (percent < 0) {
      percent = 0;
    }
    setPercentOfPrice(percent);
  };

  const handleCloseInternal = () => {
    setPercentOfPrice(null);
    handleClose();
  };

  const handlePriceChangeByPercent = (percent) => {
    if (percent) {
      setPriceValue(fullPrice * (percent / 100));
    } else {
      setPriceValue(fullPrice);
    }
  };

  const fetchAccountingCodes = async () => {
    try {
      const accountingCodes = await getAccountingCodes(true);
      setAccountingCode(accountingCodes.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePriceChangeByValue = (event) => {
    setPriceValue(event.target.value);
  };

  const handleAccountingCodeChange = (event) => {
    setChosenAccoutningCode(parseInt(event.target.value));
  };

  const handleInvoiceAccept = async () => {
    if (chosenAccoutningCode === null) {
      toast.error("Wypełnij wszystkie pola!");
      return;
    }
    const acceptanceContract = {
      stage: INVOICE_STAGE.ACCEPTED,
      price: parseFloatWithComma(priceValue),
      invoice: invoiceId,
      code_number: chosenAccoutningCode,
      comment: additionalComment,
    };
    await changeInvoiceStage(invoicePositionId, acceptanceContract, INVOICE_TYPE); // TODO??
    handleClose();
    setPercentOfPrice(null);
  };

  useEffect(() => {
    fetchAccountingCodes();
  }, []);

  useEffect(() => {
    setPriceValue(fullPrice);
  }, [fullPrice]);

  useEffect(() => {
    handlePriceChangeByPercent(percentOfPrice);
  }, [percentOfPrice]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Przypisz kwotę i zaakceptuj budżet
      </DialogTitle>
      <DialogContent>
        <Grid container justify="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={4}>
            <TextField
              label="Budżet"
              value={budgetName}
              name="budgetName"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Kwota"
              value={priceValue}
              name="price"
              onChange={handlePriceChangeByValue}
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Procent pełnej kwoty"
              value={percentOfPrice}
              name="percent"
              onChange={handlePercentChange}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-start" spacing={2} alignItems="flex-end">
          {/* <Grid item xs={4}>
                        <TextField
                            label="Kod"
                            value={"501-xxx-"}
                            name="code-beginning"
                            type="text"
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid> */}
          <Grid item xs={4}>
            <Select
              required
              onChange={handleAccountingCodeChange}
              label="Kod księgowy"
              value={chosenAccoutningCode}
              name={"code"}
              items={accountingCode}
              itemValueProp="id"
              customLabel={(item) => `${item.code_number} - ${item.name}`}
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-start" spacing={2} alignItems="flex-end">
          <Grid item xs={4}>
            <TextField
              label="Komentarz"
              value={additionalComment}
              name="additionalComment"
              onChange={(e) => setAdditionalComment(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleInvoiceAccept} color="primary">
          Zaakceptuj
        </Button>
        <Button onClick={handleCloseInternal} color="primary">
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptPendingInvoiceDialog;
