import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { getHoursFromSeconds } from "../../../utils/dates";

function LineWorkerChart({ calendar }) {
  const chartData = (calendar || []).map(el => ({
    x: el.date,
    y: getHoursFromSeconds(el.total_time_in_seconds)
  }));

  const data = [
    {
      id: "Godziny",
      color: "hsl(334, 70%, 50%)",
      data: chartData
    }
  ];
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
      lineWidth={2}
      enableArea
      axisBottom={{
        tickRotation: -90
      }}
    />
  );
}

export default LineWorkerChart;
