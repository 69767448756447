import axios from "axios";
import { API } from "../constants";

export const getAbsents = (month, year) => {
  return axios.get(
    `${API.ABSENCE_CALENDAR}absences/month/${month}/year/${year}/`
  );
};

export const addAbsence = absence => axios.post(API.ABSENCE_CALENDAR, absence);

export const getAbsenceTypes = () => axios.get(`${API.ABSENCE_CALENDAR}types`);

export const updateAbsence = absence =>
  axios.put(`${API.ABSENCE_CALENDAR}id/${absence.id}/`, absence);

export const deleteAbsence = id =>
  axios.delete(`${API.ABSENCE_CALENDAR}id/${id}/`);

export const getFreeDays = () =>
  axios.get(`${API.ABSENCE_CALENDAR}holidays/2020/`);
