import axios from "axios";
import { API } from "../constants";

export const getAccessZone = async () => {
  try {
    const response = await axios.get(API.ACCESS);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addZone = async (zone )=> {
  try {
    const response = await axios.post(API.ACCESS, 
        zone
        );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};