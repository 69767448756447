import { API } from "../constants";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllInvoices = async (type, filter) => {
  try {
    const response = await axios.get(`${API.INVOICE}${type ? type : ""}${type ? "/" : ""}${filter ? filter : ""}`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};


export const getInvoicesPerPage = async (filter, invoiceType) => {
  try {
    const response = await axios.get(
      `${API.INVOICE}${invoiceType}/accounting/all/${
        filter.page ? "p=" + filter.page + "/" : ""
      }${filter.order ? "o=" + filter.order + "/" : ""}${
        filter.rowsPerPage ? "i=" + filter.rowsPerPage + "/" : ""
      }${filter.search ? "s=" + filter.search + "/" : ""}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getAllInvoicesFiles = async (invoiceType) => {
  try {
    const response = await axios.get(`${API.INVOICE}invoicefile/${invoiceType}/`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getInvoicePDF = async (invoiceId) => {
  try {
    const response = await axios.get(`${API.INVOICE}invoicefile/${invoiceId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getAttachmentPDF = async (id) => {
  try {
    const response = await axios.get(`${API.ATTACHMENT}${id}/`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const uploadNewInvoiceDetails = async (details, invoicePath, invoiceType) => {
  try {
    const response = await axios.post(API.INVOICE + invoiceType + "/" + (invoicePath || ""), details);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data['status'])
    throw error;
  }
};

export const putNewInvoiceDetails = async (details, invoicePath, invoiceType, id) => {
  try {
    await axios.put(
      API.INVOICE + invoiceType + "/" + (invoicePath || "") + `${id || ""}` + "/",
      details
    );
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const uploadNewInvoice = async (fileForm, invoiceType) => {
  try {
    await axios.post(`${API.INVOICE}invoicefile/${invoiceType}/`, fileForm);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const uploadNewAttachment = async (fileForm) => {
  try {
    await axios.post(`${API.ATTACHMENT}`, fileForm);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getBudgetAssignedInvoice = async (id) => {
  try {
    const result = await axios.get(
      `${API.INVOICE}invoicepositions/budget/${id}/`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const acceptInvoiceByAccountant = async (id, invoiceType) => {
  try {
    const result = await axios.put(
      `${API.INVOICE}${invoiceType}/invoicepositions/accept/${id}/`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const payInvoice = async (id, invoiceType) => {
  try {
    const result = await axios.put(
      `${API.INVOICE}${invoiceType}/invoicepositions/pay/${id}/`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};


export const assignInvoiceBudget = async (invoiceBudgetAssignDetails, invoiceType) => {
  try {
    await axios.post(
      `${API.INVOICE}${invoiceType}/invoicepositions/`,
      invoiceBudgetAssignDetails
    );
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const changeInvoiceStage = async (id, stageLevelObject, invoiceType) => {
  try {
    await axios.put(`${API.INVOICE}${invoiceType}/invoicepositions/${id}/`, stageLevelObject);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const categoryAssign = async (assignDetails, invoiceType) => {
  try {
    await axios.post(`${API.INVOICE}${invoiceType}/invoicecategory/`, assignDetails);
  } catch (error) {
    console.log(error);
    toast.error("Sprawdź wypełnione dane, coś poszło nie tak.");
    throw error;
  }
};

export const getAllBudgetsWithCategories = async (invoiceType) => {
  try {
    await axios.get(`${API.INVOICE}${invoiceType}/invoicecategory/`);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getAccountingCodes = async () => {
  try {
    return await axios.get(`${API.INVOICE}accountingcode/limited/`);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getCategoriesAssignedToBudget = async (id) => {
  try {
    const response = axios.get(`${API.CATEGORY}range/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const deleteInvoice = async (id, invoiceType) => {
  try {
    const response = axios.get(`${API.INVOICE}${invoiceType}/delete/${id}/`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const deleteInvoiceUnassigned = async (id, invoiceType) => {
  try {
    await axios.delete(`${API.INVOICE}${invoiceType}/${id}/invoice/`);
    toast.success("Usunięto");
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const printReport = async (id, invoiceType, filename) => {
  try {
    axios({
      url: `${API.INVOICE}${invoiceType}/invoicereport/${id}/`,
      method: "POST",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${"raport " + filename || "raport"}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const printHistory = async (id, invoiceType, filename) => {
  try {
    axios({
      url: `${API.INVOICE}${invoiceType}/history/${id}/download/`,
      method: "POST",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${"historia " + filename || "historia"}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteRejectedInvoice = async (id, invoiceType) => {
  try {
    await axios.delete(`${API.INVOICE}${invoiceType}/invoicepositions/${id}/`);
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const downloadInvoiceReportList = async (id, filename, invoiceType) => {
  try {
    axios({
      url: `${API.BUDGET}budgetreport/${id}/${invoiceType}/`,
      method: "POST",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${filename ? "lista faktur " + filename : "lista faktur"}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const downloadAttachmentPDF = async (id, filename) => {
  try {
    axios({
      url: `${API.ATTACHMENT}${id}/download`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${filename + "_załącznik" || "załącznik"}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const downloadInvoicePDF = async (id, filename) => {
  try {
    axios({
      url: `${API.INVOICE}${id}/download/`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${filename + "_faktura" || "faktura"}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const downloadInvoicesExcel = async (tab, invoiceType) => {

  try {
    axios({
      url: `${API.INVOICE}${tab}/download/${invoiceType}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoiceType}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const downloadOutstandingInvoicesExcel = async () => {
  try {
    axios({
      url: `${API.INVOICE}outstandingreport/`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Raport_naleznosci.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const downloadStagesReport = async () => {
  try {
    axios({
      url: `${API.INVOICE}stagesreport/`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Raport_etapów.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const geArchivedInvoices = async (invoiceType) => {
  try {
    const response = await axios.get(`${API.ARCHIVE}${invoiceType}/`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getInvoiceHistory = async (id, invoiceType) => {
  try {
    const { data } = await axios.get(`${API.INVOICE}${invoiceType}/history/${id}/`);
    const invoice = data && data.invoice && data.invoice[0];

    if (invoice.positions_budget && invoice.positions_budget.length > 0) {
      invoice.positions_budget.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
    }

    return invoice;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getAllHistoryInvoices = async (invoiceType) => {
  try {
    const response = await axios.get(`${API.INVOICE}${invoiceType}/history`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getVatCodes = async () => {
  try {
    const response = await axios.get(`${API.INVOICE}vatcode/`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};
