import axios from "axios";
import {API} from "../constants";
import {toast} from "react-toastify";

export const getAllContracts = async () => {
    try {
        const response = await axios.get(`${API.CONTRACTS}`);
        return response.data;
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const getAllDeposits = async () => {
    try {
        const response = await axios.get(`${API.CONTRACTS}deposits/`);
        return response.data;
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const getContractsForBudget = async (id) => {
    try {
        const result = await axios.get(`${API.BUDGET}contracts/${id}/`);
        return result.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const uploadNewContract = async (fileForm) => {
    try {
        await axios.post(`${API.CONTRACTS}files/`, fileForm);
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const getAllContractsFiles = async () => {
    try {
        const response = await axios.get(`${API.CONTRACTS}files/`);
        return response.data;
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const getContractPDF = async (contractId) => {
    try {
        const response = await axios.get(`${API.CONTRACTS}files/${contractId}/`);
        return response.data;
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const uploadNewContractDetails = async (details) => {
    try {
        const response = await axios.post(API.CONTRACTS, details);
    } catch (error) {
        console.log(error);
        toast.error(error.response.data['status'])
        throw error;
    }
};

export const updateContractDetails = async (details, id) => {
    try {
        const response = await axios.patch(`${API.CONTRACTS}${id}/`, details);
        return response;
    } catch (error) {
        console.log(error);
        toast.error(error.response.data['status'])
        throw error;
    }
}

export const updateContractPDF = async (id, details) => {
    try {
        const response = await axios.patch(`${API.CONTRACTS}files/${id}/`, details);
        return response.data;
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const uploadNewContractAttachment = async (fileForm) => {
    try {
        await axios.post(`${API.CONTRACTS}attachments/`, fileForm);
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const getContractAttachmentPDF = async (id) => {
    try {
        const response = await axios.get(`${API.CONTRACTS}attachments/${id}/`);
        return response.data;
    } catch (error) {
        console.log(error);
        // toast.error('Coś poszło nie tak.');
        throw error;
    }
};

export const deleteContract = async (id) => {
    await axios.delete(`${API.CONTRACTS}files/${id}/`);
}