import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { getHoursFromSeconds } from "../../../utils/dates";

function ProjectsChart({ projects }) {
  if (projects.length === 0) {
    return null;
  }

  const data = projects
    .map(p => ({
      id: p.project,
      label: p.project,
      value: getHoursFromSeconds(p.total_time)
    }))
    .filter(p => p.value > 0);

  return (
    <ResponsivePie
      data={data}
      colors={{ scheme: "paired" }}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    />
  );
}

export default ProjectsChart;
