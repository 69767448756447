import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import './progressBar.css';

class ProgressBarCustom extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;

	}
	render() {
		const {percent, height} = this.props;
		return (
			<ProgressBar percent={percent} height={height} filledBackground="linear-gradient(to right, #509c35, #3bdb66)">
				<Step>
					{({ accomplished, index }) => (
						<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
					)}
				</Step>

				<Step>
					{({ accomplished, index }) => (
						<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
					)}
				</Step>
			</ProgressBar>
		);
	}
}

export default ProgressBarCustom;
