import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

function EditProject({ open, handleClose, saveProject, project, managers }) {
	const [ newProject, setNewProject ] = useState({ ...project });

	useEffect(
		() => {
			setNewProject({ ...project });
		},
		[ project ]
	);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewProject({ ...newProject, [name]: value });
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Edytuj wydział</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} justify="space-between" direction="column">
					<Grid item>
						<TextField
							label="Nazwa wydziału"
							name="project"
							value={newProject.project || ''}
							onChange={handleInputChange}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Anuluj
				</Button>
				<Button onClick={() => saveProject(newProject)} color="primary">
					Zapisz
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default EditProject;
