import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { getInvoiceHistory } from "../../../services/invoiceService";

export const InvoiceHistoryDialog = ({ open, handleClose, invoiceData }) => {
  const [invoiceHistory, setInvoiceHistory] = useState(null);
  const INVOICE_TYPE = 'sales';
  const id = invoiceData && invoiceData.id;

  const getHistory = async () => {
    if (id) {
      const history = await getInvoiceHistory(id, INVOICE_TYPE);
      setInvoiceHistory(history);
    }
  };

  useEffect(() => {
    getHistory();
  }, [id]);

  if (!invoiceData || !invoiceHistory) {
    return null;
  }

  const { due_date, invoice_number, price, status, user, positions_budget } =
    invoiceHistory;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h5">
          Historia dokumentu {invoice_number}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid container item>
            <Grid item xs={3}>
              <Typography variant="h6">Data płatności: {due_date}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Status: {status}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Użytkownik: {user}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Wartość: {price}</Typography>
            </Grid>
          </Grid>
          {positions_budget.map((position) => (
            <Grid
              container
              item
              direction="column"
              key={position.date}
              style={{ paddingBottom: 16 }}
            >
              <Grid container item>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Data: {position.date.substring(0, 10)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Status: {position.status}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Budżet: {position.budget}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Użytkownik: {position.user}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Wartość: {position.price}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
