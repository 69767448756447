import { format, getMonth, getYear } from "date-fns";

export const getHoursFromSeconds = seconds =>
  (Number(seconds) / 60 / 60).toFixed(2);

export const dateToDayMonthYear = date => {
  const day = format(date, "dd");
  const month = getMonth(date) + 1;
  const year = getYear(date);
  return { day, month, year };
};

export const defaultDateFormat = date => format(date, "yyyy-MM-dd");
