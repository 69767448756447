import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
const LandscapeDatePicker = ({ date, changeDate, views = ["month"] }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <DatePicker
        autoOk
        orientation="landscape"
        variant="static"
        openTo="date"
        views={views}
        value={date}
        onChange={changeDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export default LandscapeDatePicker;
