import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	IconButton,
	TextField,
	DialogContentText
} from '@material-ui/core';
import { getBudgetWorkrange } from '../../../services/budgetService';
import { categoryAssign } from "../../../services/invoiceService"
import Select from '../../common/Select';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { json2array } from '../../../utils/json2array';
import { toast } from 'react-toastify';
import { parseFloatWithComma } from '../../../utils/customNumberParser';

const ReviewInvoiceDialog = ({ open, handleClose, invoiceId, budgetId, remainingBudget, fullPrice }) => {
	const INVOICE_TYPE = 'sales';
	const [ categoriesList, setCategoriesList ] = useState([]);
	const [ chosenCategories, setChosenCategories ] = useState([
		{
			work_range: '',
			price: '',
			invoice_position_budget: invoiceId,
			percent: ''
		}
	]);
    const [percentOfPrice, setPercentOfPrice] = useState(null);
    const [priceValue, setPriceValue] = useState(fullPrice);

	const setEmptyCategories = () => {
		setChosenCategories(
			[
				{
					work_range: '',
					price: '',
					invoice_position_budget: invoiceId,
					percent: ''
				}
			]
		)
	}

	const addNewCategory = () => {
		setChosenCategories([
			{
				work_range: '',
				price: '',
				invoice_position_budget: invoiceId,
				percent: ''
			},
			...chosenCategories
		]);
	};

	useEffect(() => {
		fetchData();
		setEmptyCategories();
	}, [budgetId])

	const handleChange = (newChosenCategoryEvent, index, key) => {
		const { value } = newChosenCategoryEvent.target;
		if(key === 'percent') {
			handlePercentChange(newChosenCategoryEvent, index);
			chosenCategories[index][key] = parseInt(value);
		}
		if(key === 'price') {
			chosenCategories[index][key] = (value);
		} else {
			chosenCategories[index][key] = parseInt(value);
		}
		setChosenCategories([ ...chosenCategories ]);
	};

	const fetchData = async () => {
		if(budgetId){
			const categoriesList = await getBudgetWorkrange(budgetId);
			if (categoriesList) {
				const arrayCategoriesList = json2array(categoriesList.data);
				await setCategoriesList(arrayCategoriesList);
			}
		}
	};

	useEffect(() => {
		fetchData();
		chosenCategories[0]['price'] = parseFloat(remainingBudget);
		setChosenCategories([ ...chosenCategories ]);
	}, []);

	const handleSubmitFormCreation = async () => {
		for (const [idx, category] of chosenCategories.entries()) {
			try {
				category.price = parseFloatWithComma(category.price);
				await categoryAssign(category, INVOICE_TYPE);
				toast.success("Przypisano.")
			} catch (e) {
				toast.error("Nie udało się ustalić przypisać kategorii ", idx)
			}
		}
		setCategoriesList([]);
		setChosenCategories([]);
		handleClose();
	};

	useEffect(() => {
        handlePriceChangeByPercent(percentOfPrice);
    }, [percentOfPrice])

	const handlePriceChangeByPercent = (percent) => {
        if(percent) {
            setPriceValue(remainingBudget * (percent/100));
        } else {
            setPriceValue(remainingBudget);
        }
    }
    const handlePercentChange = (event, index) => {
        let percent = event.target.value;
        if(percent > 100) {
            percent = 100;
        } else if (percent < 0) {
            percent = 0;
		}
		chosenCategories[index].percent = percent;
		chosenCategories[index].price = parseFloat(remainingBudget * (percent/100)).toFixed(2);
    }

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'lg'}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">Przypisz kategorie</DialogTitle>
			<DialogContent>
			<DialogContentText>
                Pozostała kwota do przypisania {remainingBudget}zł.
            </DialogContentText>
				<Grid container spacing={2} alignItems={'center'} direction={'column'}>
					{chosenCategories &&
						chosenCategories.map((category, idx) => {
							return (
								<Grid container item spacing={3} direction={'row'} key={`cont-${idx}`}>
									<Grid item xs={4} key={`grid-${idx}`}>
										<Select
											onChange={(e) => handleChange(e, idx, 'work_range')}
											label="Kategoria"
											value={category['work_range']}
											name={'work_range'}
											items={categoriesList}
											itemValueProp="id"
											itemLabelProp="name"
											key={`cat-${idx}`}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											label="Kwota"
											value={category['price']}
											name="price"
											onChange={(e) => handleChange(e, idx, 'price')}
											key={`pri-${idx}`}
											type="number"
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											label="Procent pełnej kwoty"
											value={category['percent']}
											name="percent"
											onChange={(e) => handleChange(e, idx, 'percent')}
											type="number"
										/>
									</Grid>
								</Grid>
							);
						})}

					<Grid item xs={6}>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={''}
							aria-haspopup="true"
							onClick={addNewCategory}
							color="inherit"
						>
							<AddBoxIcon fontSize="large" />
						</IconButton>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmitFormCreation} color="primary">
					Dodaj
				</Button>
				<Button onClick={handleClose} color="primary">
					Anuluj
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReviewInvoiceDialog;
