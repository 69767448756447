import React, { useEffect, useState } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import Controls from "./Controls";
import MonthlyTable from "./MonthlyTable";
import {
  getMonthEventsForAll,
  getWeekEventsForAll,
  getRangeEventsForAll,
  getDownloadUrl,
  getRangeDownloadUrl,
  downloadXls,
} from "../../../services/eventService";
import { dateToDayMonthYear } from "../../../utils/dates";
import { getProjects } from "../../../services/projectService";
import { getDepartments } from "../../../services/administrationService";
import Select from "../../common/Select";

function BZ() {
  const [events, setEvents] = useState([]);
  const [days, setDays] = useState([]);
  const [selected, setSelected] = useState(1);
  const [date, setDate] = useState({
    month: new Date(),
    week: new Date(),
    rangeFrom: new Date(),
    rangeTo: new Date(),
  });
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState(undefined);

  const onDepartmentSelect = (event) => {
    setSelectedProject(undefined);
    setSelectedDepartment(event.target.value);
  };

  const onProjectSelect = (event) => {
    setSelectedDepartment(undefined);
    setSelectedProject(event.target.value);
  };

  const onDateChange = (name, newDate) => {
    setDate({ ...date, [name]: newDate });
  };

  const fetchEventsForMonth = async () => {
    try {
      const { month, year } = dateToDayMonthYear(date.month);
      const { days, events } = await getMonthEventsForAll(
        month,
        year,
        selectedDepartment,
        selectedProject
      );
      setDays(days);
      setEvents(events);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchEventsForWeek = async () => {
    try {
      const { day, month, year } = dateToDayMonthYear(date.week);
      const { days, events } = await getWeekEventsForAll(
        day,
        month,
        year,
        selectedDepartment,
        selectedProject
      );
      setDays(days);
      setEvents(events);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchEventsForRange = async () => {
    try {
      const {
        day: dayFrom,
        month: monthFrom,
        year: yearFrom,
      } = dateToDayMonthYear(date.rangeFrom);
      const {
        day: dayTo,
        month: monthTo,
        year: yearTo,
      } = dateToDayMonthYear(date.rangeTo);

      const { days, events } = await getRangeEventsForAll(
        dayFrom,
        monthFrom,
        yearFrom,
        dayTo,
        monthTo,
        yearTo,
        0,
        selectedDepartment,
        selectedProject
      );
      setDays(days);
      setEvents(events);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchData = () => {
    switch (selected) {
      case 0:
        fetchEventsForMonth();
        break;
      case 1:
        fetchEventsForWeek();
        break;
      case 2:
        fetchEventsForRange();
        break;
      default:
        break;
    }
  };

  const getDayMonthYearForSelectedRange = () => {
    switch (selected) {
      case 0:
        const { month, year } = dateToDayMonthYear(date.month);
        return { day: 0, month, year };
      case 1:
        return dateToDayMonthYear(date.week);
      case 2:
        const {
          day: dayFrom,
          month: monthFrom,
          year: yearFrom,
        } = dateToDayMonthYear(date.rangeFrom);
        const {
          day: dayTo,
          month: monthTo,
          year: yearTo,
        } = dateToDayMonthYear(date.rangeTo);
        return { dayFrom, monthFrom, yearFrom, dayTo, monthTo, yearTo };
      default:
        break;
    }
  };

  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setDepartments(departments);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const projects = await getProjects();
      setProjects(projects);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const handleDownloadTable = () => {
    let url = "";

    if (selected === 2) {
      const { dayFrom, monthFrom, yearFrom, dayTo, monthTo, yearTo } =
        getDayMonthYearForSelectedRange();

      url = getRangeDownloadUrl(
        dayFrom,
        monthFrom,
        yearFrom,
        dayTo,
        monthTo,
        yearTo,
        0,
        selectedDepartment,
        selectedProject
      );
    } else {
      const { day, month, year } = getDayMonthYearForSelectedRange();
      url = getDownloadUrl(
        day,
        month,
        year,
        0,
        selectedDepartment,
        selectedProject
      );
    }
    downloadXls(url);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, date, selectedDepartment, selectedProject]);

  useEffect(() => {
    fetchDepartments();
    fetchProjects();
  }, []);

  const renderTable = () => {
    switch (selected) {
      case 0:
        return (
          <MonthlyTable title="Wykaz Miesięczny" days={days} events={events} />
        );
      case 1:
        return (
          <MonthlyTable title="Wykaz Tygodniowy" days={days} events={events} />
        );
      case 2:
        return <MonthlyTable title="Wykaz Od Do" days={days} events={events} />;
      default:
        return null;
    }
  };

  return (
    <Paper square style={{ padding: 20 }}>
      <Grid container direction="column" spacing={3}>
        <Controls
          setSelected={setSelected}
          selected={selected}
          date={date}
          onDateChange={onDateChange}
        />
        <Grid container style={{ padding: 12 }}>
          <Grid item xs={4}>
            <Select
              onChange={onDepartmentSelect}
              label="Dział"
              value={selectedDepartment || ""}
              items={departments}
              itemLabelProp="department"
              itemValueProp="id"
            />
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: 20 }}>
            <Select
              onChange={onProjectSelect}
              label="Wydział"
              value={selectedProject || ""}
              items={projects}
              itemLabelProp="project"
              itemValueProp="id"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleDownloadTable}
          >
            Eksportuj
          </Button>
        </Grid>
        <Grid item style={{ maxWidth: "100%" }}>
          {renderTable()}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BZ;
