import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const propertyToTooltip = {
  total_time: "Ilość godzin",
  total_overtime: "Ilość nadgodzin"
};

function DepartmentProjectTimeChart({ data, indexBy }) {
  const newData = data.map(d => ({
    ...d,
    departmentColor: "#fff1",
    total_timeColor: "#ff1",
    total_overtimeColor: "#125"
  }));
  return (
    <ResponsiveBar
      data={newData}
      keys={["total_time", "total_overtime"]}
      indexBy={indexBy}
      margin={{ top: 10, right: 130, bottom: 80, left: 60 }}
      padding={0.3}
      colors={x => (x.id === "total_time" ? "#01BAEF" : "#FF7878")}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "ilość godzin",
        legendPosition: "middle",
        legendOffset: -40
      }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={({ id, value, color }) => (
        <strong style={{ color }}>
          {propertyToTooltip[id]}: {value}
        </strong>
      )}
      theme={{
        tooltip: {
          container: {
            background: "#333"
          }
        }
      }}
    />
  );
}

export default DepartmentProjectTimeChart;
