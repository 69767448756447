export const validateFileExtension = (fileName, extension) => {
    const fileExtension = fileName.split('.').pop();

    if(typeof extension === "string"){
        if (fileExtension === extension) {
            return true;
        }
    }
    else if(extension instanceof Array){
        for(const ex of extension) {
            if (ex === fileExtension) {
                return true;
            }
        };
    }
    return false;
};
